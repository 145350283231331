import React, { FC, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import qs from 'query-string'

import { BlockProps } from '../props'
import SectionWrapper from '../../../components/SectionWrapper'
import AdsSliderTitleButtons, {
  AdsSliderTitleButtonsProps,
} from '../../../components/AdsSliderTitleButtons'
import { ProfileCardSliderProps } from '../../../components/ProfileCardSlider'
import { actions, selectors } from '../../../redux'
import { ActionButtonVariant } from '../../../components/ActionButton/styled'
import { useServiceRequest } from '../../../hooks/useServiceRequest'
import Router from '../../../routes/Router'
import routes from '../../../routes/definitions'
import { classifiedCardTransformer } from '../../../rest/transformers/classifiedTransformer'
import { Status } from '../../../rest/types/Classified'
import { AdsCardVariant } from '../../../components/AdsCard/styled'
import { ImageTransformer } from '../../Transformers/ImageTransformer'
import BannerEditProfile, {
  BannerEditProfileProps,
} from '../../../components/BannerEditProfile'
import { ListDisplayType } from '../../../types/classified'

import { ProfilesAdsSlidersProps } from './props'
import * as SC from './styled'

const ProfilesAdsSliders: FC<BlockProps<ProfilesAdsSlidersProps>> = ({
  data,
}) => {
  const { t } = useTranslation()
  const isLogin = useSelector(selectors.auth.isConnected)
  const user = useSelector(selectors.auth.user)

  const [userProfileResponse, getUserProfiles] = useServiceRequest(
    selectors.user.getUserProfiles,
    actions.user.getUserProfilesRequest,
    actions.user.getUserProfilesReset
  )

  const [categoriesByUserProfileResponse, getCategoriesByUserProfile] =
    useServiceRequest(
      selectors.classifieds.getCategoriesByUserProfile,
      actions.classifieds.getCategoriesByUserProfileRequest,
      actions.classifieds.getCategoriesByUserProfileReset
    )

  const [classifiedsResponse, getClassifiedsWithMeta] = useServiceRequest(
    selectors.classifieds.getClassifiedsWithMeta,
    actions.classifieds.getClassifiedsWithMetaRequest,
    actions.classifieds.getClassifiedsWithMetaReset
  )

  useEffect(() => {
    if (!isLogin) {
      getClassifiedsWithMeta({
        filters: {
          status: {
            $eq: Status.Published,
          },
        },
        pagination: {
          limit: 6,
        },
        sort: 'publishedAt:desc',
        populate: {
          user: {
            populate: {
              speciality: '*',
            },
          },
          classifiedCategory: {
            field: ['name'],
          },
          classified_views: {
            fields: ['id'],
            populate: {
              user: {
                fields: ['id'],
              },
            },
          },
        },
      })
    }
  }, [getClassifiedsWithMeta, isLogin])

  useEffect(() => {
    if (!isLogin) {
      getUserProfiles()
    }
  }, [getUserProfiles, isLogin])

  useEffect(() => {
    if (isLogin) {
      getCategoriesByUserProfile({ userProfileId: user?.profile?.id })
    }
  }, [getCategoriesByUserProfile, isLogin, user])

  const editProfileProps: BannerEditProfileProps = useMemo(
    () => ({
      title: t('banner_edit_profile_label'),
      button: {
        label: t('banner_edit_profile_button_label'),
        variant: ActionButtonVariant.quinary,
        href: Router.getRouteUrl(routes.account),
      },
      profileName:
        user?.profile?.name ?? t('banner_edit_profile_default_profile'),
    }),
    [t, user?.profile?.name]
  )

  const profileCardProps: ProfileCardSliderProps = useMemo(
    () => ({
      title: data?.title,
      items: userProfileResponse?.data?.map?.((profile) => ({
        title: profile?.name ?? '',
        description: profile?.description ?? '',
        button: {
          label: t('ads_profile_card_button_label'),
          variant: ActionButtonVariant.quinary,
          href: Router.getRouteUrl(routes.loginOrRegister),
        },
        backgroundImage: ImageTransformer(profile?.image?.data),
        isSelected: false,
      })),
    }),
    [data?.title, t, userProfileResponse?.data]
  )

  const adsSliderTitleButtonsNotConnectedProps: AdsSliderTitleButtonsProps =
    useMemo(
      () => ({
        title: t('profiles_ads_sliders_default_title'),
        adsNumber: `(${String(
          classifiedsResponse?.data?.meta?.pagination?.total
        )} ${
          classifiedsResponse?.data?.meta?.pagination?.total &&
          classifiedsResponse?.data?.meta?.pagination?.total > 1
            ? t('ads_number_listing_plural_label')
            : t('ads_number_listing_singular_label')
        })`,
        slider: {
          items:
            classifiedsResponse?.data?.data?.map((classified) =>
              classifiedCardTransformer({
                data: classified,
                t,
                contentVariant: 'default',
              })
            ) ?? [],
        },
        buttons: {
          items: [
            {
              text: t('profiles_ads_sliders_discover_ads_button_label'),
              variant: ActionButtonVariant.quaternary,
              href: Router.getRouteUrl(routes.classifiedSearch),
            },
            {
              text: t('profiles_ads_sliders_map_button_label'),
              variant: ActionButtonVariant.secondary,
              href:
                Router.getRouteUrl(routes.classifiedSearch) +
                '?' +
                qs.stringify(
                  {
                    displayType: ListDisplayType.Map,
                  },
                  { arrayFormat: 'comma' }
                ),
            },
          ],
        },
      }),
      [classifiedsResponse?.data, t]
    )

  const adsSliderTitleButtonsConnected = useMemo(() => {
    if (!Array.isArray(categoriesByUserProfileResponse?.data)) {
      return <React.Fragment />
    }

    return categoriesByUserProfileResponse.data
      .filter(
        (category) =>
          category?.classifieds?.length && category?.classifieds?.length > 0
      )
      .map((category, index) => {
        const key = category?.id ?? Math.random().toString(36).substr(2, 9)
        return category?.classifieds?.length &&
          category?.classifieds?.length > 0 ? (
          <SectionWrapper key={key} withBackgroundColor={index % 2 !== 0}>
            <AdsSliderTitleButtons
              title={category?.name ?? ''}
              adsNumber={`(${String(category?.totalClassifieds)} ${
                category?.totalClassifieds && category?.totalClassifieds > 1
                  ? t('ads_number_listing_plural_label')
                  : t('ads_number_listing_singular_label')
              })`}
              slider={{
                items:
                  category?.classifieds?.map((classified) =>
                    classifiedCardTransformer({
                      data: classified,
                      t,
                      colorVariant:
                        index % 2 === 0
                          ? AdsCardVariant.secondary
                          : AdsCardVariant.primary,
                      contentVariant: 'default',
                    })
                  ) ?? [],
              }}
              buttons={{
                items: [
                  {
                    text: t('profiles_ads_sliders_discover_ads_button_label'),
                    variant: ActionButtonVariant.quaternary,
                    href:
                      Router.getRouteUrl(routes.classifiedSearch) +
                      '?' +
                      qs.stringify(
                        {
                          ...(category?.id && {
                            offerType: category?.id,
                          }),
                        },
                        { arrayFormat: 'comma' }
                      ),
                  },
                  {
                    text: t('profiles_ads_sliders_map_button_label'),
                    variant: ActionButtonVariant.secondary,
                    href:
                      Router.getRouteUrl(routes.classifiedSearch) +
                      '?' +
                      qs.stringify(
                        {
                          ...(category?.id && {
                            offerType: category?.id,
                          }),
                          displayType: ListDisplayType.Map,
                        },
                        { arrayFormat: 'comma' }
                      ),
                  },
                ],
              }}
            />
          </SectionWrapper>
        ) : (
          <React.Fragment key={key} />
        )
      })
  }, [categoriesByUserProfileResponse?.data, t])

  return (
    <>
      {isLogin && (
        <>
          <SC.SectionBannerEditProfile>
            <BannerEditProfile {...editProfileProps} />
          </SC.SectionBannerEditProfile>
          {adsSliderTitleButtonsConnected}
        </>
      )}
      {!isLogin && (
        <>
          <SectionWrapper>
            <SC.ProfileCardSliderBlock {...profileCardProps} />
          </SectionWrapper>

          {classifiedsResponse?.data?.data?.length &&
            classifiedsResponse?.data?.data?.length > 0 && (
              <SectionWrapper withBackgroundColor>
                <SC.AdsSliderBlock
                  {...adsSliderTitleButtonsNotConnectedProps}
                />
              </SectionWrapper>
            )}
        </>
      )}
    </>
  )
}
export default ProfilesAdsSliders
