import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import { mq, sizes } from '../../theme'
import SwiperPagination from '../SwiperPagination'

export const ProfileCardSlider = styled.div``

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin: 0;
`

export const SwiperComponent = styled(Swiper)`
  margin-top: 2rem;
`

export const Navigation = styled(SwiperPagination)`
  @media ${mq(sizes.desktop)} {
    justify-content: flex-end;
    margin-top: 4.6rem;
  }
`
