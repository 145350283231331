import React, { FC } from 'react'
import cx from 'classnames'

import { Icons } from '../Icon'
import { LinkProps } from '../Link'

import * as SC from './styled'
import { AdsCardVariant } from './styled'

export type AdsCardProps = {
  className?: string
  title?: string
  publicationDate?: string
  description?: string
  localization?: string
  offerType?: string
  adViewsNumber?: string
  adCommentNumber?: string
  link?: LinkProps
  variant?: AdsCardVariant
}

const AdsCard: FC<AdsCardProps> = ({
  className,
  title,
  publicationDate,
  description,
  localization,
  offerType,
  adViewsNumber,
  adCommentNumber,
  link,
  variant = AdsCardVariant.primary,
}) => {
  return (
    <SC.AdsCard className={cx('AdsCard', className)} $variant={variant}>
      <SC.Title>{title}</SC.Title>
      <SC.PublicationDate $variant={variant}>
        {publicationDate}
      </SC.PublicationDate>
      <SC.TextSeparator $variant={variant} />
      {description && (
        <>
          <SC.Description $variant={variant}>{description}</SC.Description>
          <SC.TextSeparator $variant={variant} />
        </>
      )}
      <SC.Localization>{localization}</SC.Localization>
      <SC.OfferType>{offerType}</SC.OfferType>
      <SC.Wrapper $description={description}>
        {(adViewsNumber || adCommentNumber) && (
          <SC.IconsWrapper>
            <SC.IconWrapper>
              <SC.IconEye icon={Icons.eye} width={24} height={24} />
              <SC.AdViewsNumber>{adViewsNumber}</SC.AdViewsNumber>
            </SC.IconWrapper>
            <SC.IconSeparator />
            <SC.IconWrapper>
              <SC.IconComment icon={Icons.comment} width={16} height={16} />
              <SC.AdCommentNumber>{adCommentNumber}</SC.AdCommentNumber>
            </SC.IconWrapper>
          </SC.IconsWrapper>
        )}
        {link && (
          <SC.Lk {...link}>
            {link.text}
            <SC.IconArrowRight icon={Icons.arrowRight} width={16} height={13} />
          </SC.Lk>
        )}
      </SC.Wrapper>
    </SC.AdsCard>
  )
}

export default AdsCard
