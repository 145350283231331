import styled from 'styled-components'

import ActionButton from '../ActionButton'

export const BannerEditProfile = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 3rem;
  background-color: ${({ theme }) => theme.colors.bluePrimary};
  border-radius: 1rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Wrapper = styled.div``

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  color: ${({ theme }) => theme.colors.pureWhite};
  margin: 0 0 0.5rem 0;
`

export const ProfileName = styled.span`
  margin-left: 0.5rem;
`

export const Button = styled(ActionButton)`
  text-align: center;
`
