import React, { FC } from 'react'
import cx from 'classnames'

import { AdsSliderProps } from '../AdsSlider'
import { ButtonsProps } from '../Buttons'

import * as SC from './styled'

export type AdsSliderTitleButtonsProps = {
  className?: string
  title: string
  adsNumber: string
  slider: AdsSliderProps
  buttons: ButtonsProps
}

const AdsSliderTitleButtons: FC<AdsSliderTitleButtonsProps> = ({
  className,
  title,
  adsNumber,
  slider,
  buttons,
}) => {
  return (
    <SC.AdsSliderTitleButtons
      className={cx('AdsSliderTitleButtons', className)}
    >
      <SC.WrapperTitle>
        <SC.Title>{title}</SC.Title>
        <SC.AdsNumber>{adsNumber}</SC.AdsNumber>
      </SC.WrapperTitle>
      <SC.Slider {...slider} />
      <SC.Btn {...buttons} />
    </SC.AdsSliderTitleButtons>
  )
}

export default AdsSliderTitleButtons
