import styled from 'styled-components'
import Image from 'next/image'

import ActionButton from '../ActionButton'

export const ProfileCard = styled.div`
  border-radius: 2rem;
  height: 42rem;
  position: relative;
  overflow: hidden;
  display: flex;
`

export const Wrapper = styled.div<{ $isSelected: boolean }>`
  background-color: ${({ theme }) => theme.colors.blueTransparent};
  padding: 2rem;
  border-radius: 2rem;
  color: ${({ theme }) => theme.colors.pureWhite};
  margin-top: auto;
  pointer-events: none;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.pinkButton};
  }

  > span,
  a {
    pointer-events: auto;
  }
`

export const BackgroundImage = styled(Image)`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: -1;
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.firaSansXlBold};
  border-bottom: 2px solid ${({ theme }) => theme.colors.pureWhite};
  padding-bottom: 1.1rem;
  margin: 0;
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.montserratSm};
  margin-top: 1rem;
`

export const Button = styled(ActionButton)`
  margin-top: 1.5rem;
  width: 100%;
`
