import React, { FC, useRef, useState } from 'react'
import cx from 'classnames'
import { default as SwiperInstance, Navigation, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import ProfileCard, { ProfileCardProps } from '../ProfileCard'

import * as SC from './styled'

export type ProfileCardSliderProps = {
  className?: string
  title?: string
  items?: Array<ProfileCardProps>
}

const ProfileCardSlider: FC<ProfileCardSliderProps> = ({
  className,
  title,
  items,
}) => {
  const [swiperInstance, setSwiper] = useState<SwiperInstance>()
  const paginationRef = useRef<any>(null)

  return (
    <SC.ProfileCardSlider className={cx('ProfileCardSlider', className)}>
      {title && <SC.Title>{title}</SC.Title>}
      <SC.SwiperComponent
        className={className}
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        breakpoints={{
          576: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 3.2,
          },
          1440: {
            slidesPerView: 4.2,
          },
        }}
        pagination={{
          clickable: true,
          el: paginationRef?.current,
        }}
        onInit={(swiper) => setSwiper(swiper)}
      >
        {items?.map((item, index) => (
          <SwiperSlide key={index}>
            <ProfileCard {...item} />
          </SwiperSlide>
        ))}
        <SC.Navigation
          paginationRef={paginationRef}
          onPrev={() => swiperInstance?.slidePrev()}
          onNext={() => swiperInstance?.slideNext()}
        />
      </SC.SwiperComponent>
    </SC.ProfileCardSlider>
  )
}

export default ProfileCardSlider
