import React, { FC } from 'react'

import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type BannerEditProfileProps = {
  className?: string
  title: string
  profileName: string
  button: ActionButtonProps
}

const BannerEditProfile: FC<BannerEditProfileProps> = ({
  className,
  title,
  profileName,
  button,
}) => {
  return (
    <SC.BannerEditProfile className={className}>
      <SC.Wrapper>
        <SC.Title>
          {title}
          <SC.ProfileName>{profileName}</SC.ProfileName>
        </SC.Title>
      </SC.Wrapper>
      <SC.Button {...button} />
    </SC.BannerEditProfile>
  )
}

export default BannerEditProfile
