import styled from 'styled-components'

import { mq, sizes } from '../../theme'
import AdsSlider from '../AdsSlider'
import Buttons from '../Buttons'

export const AdsSliderTitleButtons = styled.div``

export const WrapperTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media ${mq(sizes.tablet)} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 6rem;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans2XlBold};
  color: ${({ theme }) => theme.colors.bluePrimary};
  margin: 0;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.firaSans3XlBold}
  }
`

export const AdsNumber = styled.p`
  ${({ theme }) => theme.textStyles.vujahdayMedium};
`

export const Slider = styled(AdsSlider)`
  margin-top: 0.6rem;
  .NavigationPaginationBlock {
    margin-bottom: 3rem;

    @media ${mq(sizes.desktop)} {
      margin-top: 6rem;
      margin-bottom: 1rem;
    }
  }
`

export const Btn = styled(Buttons)`
  @media ${mq(null, sizes.mobile)} {
    a {
      width: 100%;
    }
  }
`
