import { FC } from 'react'
import classNames from 'classnames'

import { Icons } from '../Icon'

import * as SC from './styled'

export type SwiperPaginationProps = {
  className?: string
  paginationRef?: React.RefObject<any>
  onPrev?: () => void
  onNext?: () => void
}

const SwiperPagination: FC<SwiperPaginationProps> = ({
  className,
  paginationRef,
  onPrev,
  onNext,
}) => {
  return (
    <SC.NavigationPaginationBlock
      className={classNames('NavigationPaginationBlock', className)}
    >
      <SC.IconEl onClick={onPrev}>
        <SC.Arrow icon={Icons.chevronLeft} width={20} />
      </SC.IconEl>
      <SC.SwiperCustomPagination ref={paginationRef} />
      <SC.IconEl onClick={onNext}>
        <SC.Arrow icon={Icons.chevronRight} width={20} />
      </SC.IconEl>
    </SC.NavigationPaginationBlock>
  )
}

export default SwiperPagination
