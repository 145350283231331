import React, { FC, Fragment, useState } from 'react'
import Swiper, { Navigation, Pagination } from 'swiper'
import { SwiperSlide } from 'swiper/react'

import AdsCard, { AdsCardProps } from '../AdsCard'
import { Icons } from '../Icon'

import * as SC from './styled'
import 'swiper/css'
import 'swiper/css/pagination'

export type AdsSliderProps = {
  className?: string
  items?: Array<AdsCardProps>
}

const AdsSlider: FC<AdsSliderProps> = (props) => {
  const { className = '', items } = props
  const [swiperInstance, setSwiper] = useState<Swiper>()

  return (
    <Fragment>
      <SC.SwiperComponent
        className={className}
        modules={[Navigation, Pagination]}
        spaceBetween={30}
        breakpoints={{
          576: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2.2,
          },
          992: {
            slidesPerView: 3.2,
          },
          1440: {
            slidesPerView: 4.2,
          },
        }}
        pagination={{
          clickable: true,
          el: '.swiper-custom-pagination',
        }}
        onInit={(swiper) => setSwiper(swiper)}
      >
        {items &&
          items.map((item, index) => (
            <SwiperSlide key={index}>
              <AdsCard {...item} />
            </SwiperSlide>
          ))}
        <SC.NavigationPaginationBlock className={'NavigationPaginationBlock'}>
          <SC.IconEl onClick={() => swiperInstance?.slidePrev()}>
            <SC.Arrow icon={Icons.chevronLeft} width={20} />
          </SC.IconEl>
          <SC.SwiperCustomPagination className="swiper-custom-pagination" />
          <SC.IconEl onClick={() => swiperInstance?.slideNext()}>
            <SC.Arrow icon={Icons.chevronRight} width={20} />
          </SC.IconEl>
        </SC.NavigationPaginationBlock>
      </SC.SwiperComponent>
    </Fragment>
  )
}

export default AdsSlider
