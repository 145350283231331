import styled from 'styled-components'

import ProfileCardSlider from '../../../components/ProfileCardSlider'
import AdsSliderTitleButtons from '../../../components/AdsSliderTitleButtons'
import SectionWrapper from '../../../components/SectionWrapper'

export const SectionBannerEditProfile = styled(SectionWrapper)`
  .SectionSpacer {
    margin-bottom: 0;
  }
`

export const ProfileCardSliderBlock = styled(ProfileCardSlider)`
  .swiper {
    overflow: unset;
  }
  .ProfileCard {
    > div {
      min-height: 28rem;
      display: flex;
      flex-direction: column;
    }

    a {
      margin-top: auto;
    }
  }
`

export const AdsSliderBlock = styled(AdsSliderTitleButtons)`
  .swiper {
    overflow: unset;
  }
`
