import React, { FC } from 'react'
import cx from 'classnames'
import { ImageProps } from 'next/image'

import { ActionButtonProps } from '../ActionButton'
import { ActionButtonVariant } from '../ActionButton/styled'

import * as SC from './styled'

export type ProfileCardProps = {
  className?: string
  title: string
  description: string
  button: ActionButtonProps
  backgroundImage?: ImageProps
  isSelected: boolean
}

const ProfileCard: FC<ProfileCardProps> = ({
  className,
  title,
  description,
  button,
  backgroundImage,
  isSelected,
}) => {
  return (
    <SC.ProfileCard className={cx('ProfileCard', className)}>
      {backgroundImage && <SC.BackgroundImage {...backgroundImage} />}
      <SC.Wrapper $isSelected={isSelected}>
        <SC.Title>{title}</SC.Title>
        <SC.Description>{description}</SC.Description>
        <SC.Button
          {...button}
          {...(isSelected
            ? { variant: ActionButtonVariant.senary }
            : { variant: ActionButtonVariant.quinary })}
        />
      </SC.Wrapper>
    </SC.ProfileCard>
  )
}

export default ProfileCard
