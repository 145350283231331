import React, { FC } from 'react'
import cx from 'classnames'

import { ActionButtonProps } from '../ActionButton'

import * as SC from './styled'

export type ButtonsProps = {
  className?: string
  items: Array<ActionButtonProps>
}

const Buttons: FC<ButtonsProps> = ({ className, items }) => {
  return (
    <SC.Wrapper className={cx('Buttons', className)}>
      {items.map((item, index) => (
        <SC.Button key={index} {...item} />
      ))}
    </SC.Wrapper>
  )
}

export default Buttons
