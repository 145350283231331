import styled from 'styled-components'
import { Swiper } from 'swiper/react'

import Icon from '../Icon'
import { mq, sizes } from '../../theme'

export const SwiperComponent = styled(Swiper)`
  .swiper-pagination-bullet {
    background: ${({ theme }) => theme.colors.greyFields};
    height: 7px;
    width: 7px;
    opacity: 0.3;
  }
  .swiper-pagination-bullet-active {
    background: ${({ theme }) => theme.colors.greyFields};
    height: 10px;
    width: 10px;
    opacity: 1;
  }
  .swiper-wrapper {
    display: flex;
  }
  .swiper-slide {
    height: auto;

    > div {
      height: 100%;
    }
  }
`

export const NavigationPaginationBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;
  margin-top: 3.4rem;

  @media ${mq(sizes.desktop)} {
    justify-content: flex-end;
  }
`

export const SwiperCustomPagination = styled.div`
  width: max-content !important;
  display: flex;
  align-items: center;
`

export const IconEl = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.pinkButton};
  &:hover {
    cursor: pointer;
  }
`

export const Arrow = styled(Icon)``
